

























import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ROUTE_PATHS_STATIC } from '@/router'

export default Vue.extend({
  name: 'ForbiddenPage',

  data() {
    return {
      dialog: true,
      isLoad: true,
      accountSettings: {} as any,
    }
  },
  // TODO вывести на экран чего конкретно не хватает
  async created() {
    try {
      this.isLoad = true
      // Проверка прав
      // Проверка настроек
      // проверка реджистри
      //@ts-ignore
      this.accountSettings = await this.fetchUserAccountSettings()
      // TODO Дописать loader, дописать проверки
      if (
        this.userPermissions &&
        this.getUserInfo &&
        this.initialSystemSettings &&
        !(this.accountSettings instanceof Error || this.accountSettings instanceof TypeError)
      ) {
        this.$router.push({ path: ROUTE_PATHS_STATIC.BASE })
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoad = false
    }
  },

  computed: {
    ...mapGetters({
      userPermissions: 'auth/userPermissions',
      getUserInfo: 'usersInfo/getUserInfo',
      initialSystemSettings: 'settings/initialSystemSettings',
    }),
    getUserPermissions() {
      return this.userPermissions && this.userPermissions.length ? true : false
    },
    checkUserInfo() {
      return this.getUserInfo && Object.keys(this.getUserInfo).length !== 0 ? true : false
    },
    getInitialSystemSettings() {
      return this.initialSystemSettings && Object.keys(this.initialSystemSettings).length !== 0 ? true : false
    },
    getAccountSettings() {
      return this.accountSettings instanceof Error || this.accountSettings instanceof TypeError ? false : true
    },
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      getUserInfo: 'usersInfo/getUserInfo',
      fetchUserAccountSettings: 'settings/fetchUserAccountSettings',
    }),

    goNaher() {
      // @ts-ignore
      this.logout()
      setTimeout(() => {
        this.$router.push({ path: '/logout' })
      }, 500)
    },
  },
})
