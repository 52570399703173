




































































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios, { AxiosError } from 'axios'
import {
  LOCAL_STORAGE_KEYS,
  Pagination,
  UserSessionSettings,
  UserSettingCreateRequestDto,
  UserSettingDto,
} from '@docshouse/dh-ui-types'
import { FormattedSetting } from '@/types/global.types'
import { convertPaginationToRequestParams, generateNotificationManyOperationMessage } from '@docshouse/dh-ui-components'

/**
 * EMITS:
 * 1. "close-dialog" - при закрытии на кнопки Отмена и "крестик", без изменний классов
 */
export default Vue.extend({
  name: 'SettingsUserResetDialog',

  props: {
    isDialogOpenModel: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      currentSessionSettings: null as UserSettingDto | null,
      sessionSettingsAsArray: [] as FormattedSetting[],
      selectedSessionSettings: [] as FormattedSetting[],
      componentsSettings: [] as UserSettingDto[],
      selectedComponentsSettings: [] as UserSettingDto[],
      isDeleteUsersComponentsSettingsProcess: false,
      sessionSettingsHeaders: [{ text: '', value: 'title' }, {}, {}],
      componentsSettingsHeaders: [
        {
          text: 'resetSettingsTable.description',
          value: 'description',
          sortable: false,
          isVisible: true,
          width: 200,
        },
        { text: 'resetSettingsTable.type', value: 'containerType', sortable: false, isVisible: true },
        { text: 'resetSettingsTable.value', value: 'value', sortable: false, isVisible: true },
      ],
      userComponentsPagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      } as Pagination<UserSettingDto>,
      isConfirmResetAllDialogOpen: false,
      isDeletingAllSettings: false,
    }
  },

  computed: {
    ...mapGetters({
      getUserInfo: 'usersInfo/getUserInfo',
      userProfile: 'settings/userProfile',
      systemTheme: 'settings/systemTheme',
      systemLanguage: 'settings/systemLanguage',
      systemHomepage: 'settings/systemHomepage',
      currentTheme: 'settings/systemCurrentTheme',
    }),
    isSettingsResetDialogOpen: {
      get: function (): boolean {
        return this.isDialogOpenModel
      },
      set: function (isOpen: boolean) {
        this.$emit('update:isDialogOpenModel', isOpen)
      },
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions({
      fetchUserAccountSettings: 'settings/fetchUserAccountSettings',
      updateSettingById: 'settings/updateSettingById',
      setUserAccountSettings: 'settings/setUserAccountSettings',
      deleteSettingById: 'settings/deleteSettingById',
      deleteCurrentUserSetting: 'settings/deleteCurrentUserSetting',
      getCurrentUserSettingsList: 'settings/getCurrentUserSettingsList',
      checkUserSettingsForResetAvaiable: 'settings/checkUserSettingsForResetAvaiable',
      showMessage: 'events/showMessage',
    }),
    ...mapMutations({
      setUserSettingChangeStatus: 'settings/setUserSettingChangeStatus',
    }),

    async init() {
      const parsedUserSessionSettings = await this.getCurrentUserSessionSettings()
      this.sessionSettingsAsArray = this.transformUserSessionSettings(parsedUserSessionSettings)
      await this.getUserComponentsSettings()
    },

    async getCurrentUserSessionSettings(): Promise<UserSessionSettings> {
      const currentSessionSettings = await this.fetchUserAccountSettings()
      this.currentSessionSettings = currentSessionSettings[0]
      let parsedSettings = {} as UserSessionSettings

      if (currentSessionSettings && currentSessionSettings.length) {
        parsedSettings = JSON.parse(currentSessionSettings[0].value)
      }

      return parsedSettings
    },

    async getUserComponentsSettings(pagination?: Pagination<UserSettingDto>) {
      const params = convertPaginationToRequestParams(pagination ?? this.userComponentsPagination)

      params.containerType = 'COMPONENT'

      try {
        this.componentsSettings = await this.getCurrentUserSettingsList(params)
      } catch (error) {
        if (axios.isAxiosError(error) && error.message) {
          this.showMessage({ payload: { message: error.message, color: 'error' } })
        }
      }
    },

    async resetSelectedSettings() {
      if (this.selectedSessionSettings.length) {
        try {
          const response = await this.updateSettingById({
            id: this.currentSessionSettings?.id,
            data: this.getResetSessionPayload(),
          })

          this.setUserAccountSettings(JSON.parse(response.value))
          this.showMessage({
            payload: {
              message: this.$t('notifications.deleteUserSettingsSuccess'),
              color: 'success',
            },
          })

          this.setUserSettingChangeStatus(false)
        } catch (error) {
          if (axios.isAxiosError(error) && error.message) {
            this.showMessage({ payload: { message: error.message, color: 'error' } })
          }
        }
      }

      if (this.selectedComponentsSettings.length) {
        await this.deleteComponentSettings()
      }

      await this.checkUserSettingsForResetAvaiable()
      this.closeDialog()
    },

    getResetSessionPayload() {
      const sessionSettings = {
        language: this.systemLanguage,
        profile: this.userProfile,
        theme: this.systemTheme,
        homepage: this.systemHomepage,
        currentTheme: this.currentTheme,
      }

      for (const selectedItem of this.selectedSessionSettings) {
        switch (selectedItem.settingsType) {
          case 'language':
            sessionSettings.language = 'ru'
            this.$i18n.locale = 'ru'
            this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.LOCALE, null, 'ru')
            break
          case 'theme':
            sessionSettings.theme = 'light'
            this.$vuetify.theme.dark = false
            this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.DARK_THEME, null, this.$vuetify.theme.dark)
            break
          case 'currentTheme':
            delete sessionSettings.currentTheme
            this.$localStorageHandler('remove', false, 'selectedTheme', null)
            break
          case 'homepage':
            delete sessionSettings.homepage
            break
          case 'profile':
            delete sessionSettings.profile
            break
          default:
            break
        }
      }

      const payload: UserSettingCreateRequestDto = {
        containerType: 'USER',
        container: this.getUserInfo?.preferredUsername,
        key: 'user-settings',
        userName: this.getUserInfo?.preferredUsername,
        application: 'ui-base',
        isGeneral: false,
        description: this.$t('sessionUserSettings') as string,
        value: JSON.stringify(sessionSettings),
      }

      return payload
    },

    setSessionSettingsToDefaults() {
      this.sessionSettingsAsArray = []
      this.$i18n.locale = 'ru'
      this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.LOCALE, null, 'ru')
      this.$vuetify.theme.dark = false
      this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.DARK_THEME, null, this.$vuetify.theme.dark)
      this.setUserAccountSettings({ language: 'ru', theme: 'light' })
      this.$localStorageHandler('remove', false, 'selectedTheme', null)
    },

    async deleteComponentSettings() {
      this.isDeleteUsersComponentsSettingsProcess = true

      const successResults: UserSettingDto[] = []
      const errorResults: { name?: string; errorText: string }[] = []
      const usersComponentsSettingsRequestsArray = this.selectedComponentsSettings.map((setting) =>
        this.deleteSettingById(setting.id)
          .then(() => {
            successResults.push(setting)
          })
          .catch((error: AxiosError) => {
            errorResults.push({
              name: setting.description,
              errorText: error.message,
            })
          })
      )
      await Promise.allSettled(usersComponentsSettingsRequestsArray)

      const infoForMessage = generateNotificationManyOperationMessage(
        successResults.length,
        errorResults,
        this.$t('notifications.deleteUserSettingsSuccess')
      )
      this.showMessage({ payload: { message: infoForMessage.message, color: infoForMessage.color } })
      this.selectedComponentsSettings = []

      this.isDeleteUsersComponentsSettingsProcess = false
    },

    closeDialog() {
      this.$emit('close-dialog')
      this.isSettingsResetDialogOpen = false
    },

    transformUserSessionSettings(settings: UserSessionSettings): FormattedSetting[] {
      return Object.entries(settings).map(([key, value]) => ({
        title: `${key}: ${value ?? ''}`,
        settingsType: key,
        value,
      }))
    },

    async resetAllSettings() {
      if (this.sessionSettingsAsArray.length > 0 || this.componentsSettings.length > 0) {
        try {
          this.isDeletingAllSettings = true
          await this.deleteCurrentUserSetting()

          this.showMessage({
            payload: { message: this.$t('notifications.deleteUserSettingsSuccess'), color: 'success' },
          })

          this.userComponentsPagination.page = 1
          this.init()
          this.setSessionSettingsToDefaults()
          await this.checkUserSettingsForResetAvaiable()
          this.closeDialog()
        } catch (error) {
          if (axios.isAxiosError(error) && error.message) {
            this.showMessage({ payload: { message: error.message, color: 'error' } })
          }
        } finally {
          this.isDeletingAllSettings = false
          this.isConfirmResetAllDialogOpen = false
        }
      }
    },
  },
})
