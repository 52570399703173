import Vue from 'vue'
import store from '@/store'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import { LOCAL_STORAGE_KEYS, RouteDescription, UserSettingDto } from '@docshouse/dh-ui-types'
import PageDoesNotExistView from '@/views/PageDoesNotExistView.vue'
import DashboardView from '@/views/DashboardView.vue'
import LoginError from '@/views/LoginError.vue'
import ForbiddenPage from '@/views/ForbiddenPage.vue'
import { checkUrlLocation, navigate } from '@/common/utils'
import { localStorageHandler } from '@docshouse/dh-ui-components'
import { AxiosError } from 'axios'

Vue.use(VueRouter)

export const ROUTE_PATHS_STATIC = {
  BASE: process.env.VUE_APP_ADMIN_PATH,
  // AUTH: '/auth',
  PAGE_DOES_NOT_EXIST: '/404',
  PAGE_LOGOUT: '/logout',
  PAGE_LOGIN_ERROR: '/login',
  PAGE_FORBBIDEN: '/forbidden',
}

const routes: RouteConfig[] = [
  {
    path: ROUTE_PATHS_STATIC.BASE,
    component: DashboardView,
  },
  {
    path: ROUTE_PATHS_STATIC.PAGE_DOES_NOT_EXIST,
    component: PageDoesNotExistView,
  },
  {
    path: ROUTE_PATHS_STATIC.PAGE_LOGOUT,
  },
  {
    path: ROUTE_PATHS_STATIC.PAGE_LOGIN_ERROR,
    component: LoginError,
  },
  {
    path: ROUTE_PATHS_STATIC.PAGE_FORBBIDEN,
    component: ForbiddenPage,
  },
  // остальные роуты верхнего уровня будут зарегистрированы динамически
]

const router = new VueRouter({
  mode: 'history',
  base: ROUTE_PATHS_STATIC.BASE,
  routes,
})

router.beforeEach(async (to, from, next) => {
  checkData(to)
  if (!(await handleInitialSettings(next)) && !checkUrlLocation()) return

  // if (to.path === ROUTE_PATHS_STATIC.AUTH) {
  //   if (!isAuthenticated()) {
  //     await checkSessionData()

  //     return !isAuthenticated() ? next() : next({ path: ROUTE_PATHS_STATIC.BASE })
  //   }
  // }

  // if (!isAuthenticated()) {
  //   await checkSessionData()

  //   if (!isAuthenticated()) {
  //     setUnresolvedInitialNavigationPath(to.fullPath)
  //   }

  //   checkTokenExpiry()
  //   return next({ path: to.fullPath })
  // }

  // if (!isUserPermissionsReceived()) {
  //   await checkUserPermissions()

  //   setUnresolvedInitialNavigationPath(to.fullPath)

  //   await initializationFinishedCallback()
  // }

  if (!isUserPermissionsReceived() && !checkUrlLocation()) {
    await checkUserPermissions()
    const userPermissions: any = getUserPermissions()
    if (userPermissions.length === 0) {
      next({ path: ROUTE_PATHS_STATIC.PAGE_FORBBIDEN })
      return false
    }
    // if (!isUserPermissionsReceived()) {
    //   setUnresolvedInitialNavigationPath(to.fullPath)
    // }
    await initializationFinishedCallback()

    if (to.fullPath === '/') {
      return next({ path: ROUTE_PATHS_STATIC.BASE })
    } else {
      return navigate({ path: to.fullPath, query: { updateRootTab: 'true' } })
    }
  }

  return next()
})

function checkData(to: any) {
  if (
    to.path.includes('/logout') &&
    localStorage.getItem('session_data') === null &&
    localStorage.getItem('registry_data') === null
  ) {
    if (process.env.NODE_ENV === 'development') {
      window.location.href =
        process.env.BASE_URL + 'proxy/dh-gateway-api-service/logout?post_logout_redirect_uri=' + window.location.href
    } else {
      window.location.href = '/' + 'dh-gateway-api-service/logout?post_logout_redirect_uri=' + window.location.href
    }
    return
  }
}

async function handleInitialSettings(next: NavigationGuardNext<Vue>): Promise<boolean> {
  if (!isInitialSettingsReceived() && !isFailedToReceiveInitialSystemSettings() && !checkUrlLocation()) {
    const resp = await getUserInfo()
    checkUserInfo(resp, next)
    const setting = await getInitialSystemSettings()
    checkInitialSystemSettings(setting, next)
    const userSessionSettings = await fetchUserAccountSettings()
    checkUserAccountSettings(userSessionSettings, next)
    userSessionSettingsFunction(userSessionSettings)
    await getAuditDictionary()
    if (!isInitialSettingsReceived()) {
      // next({ path: ROUTE_PATHS_STATIC.AUTH })
      return false
    }
  }
  return true
}

function checkUserInfo(resp: any, next: NavigationGuardNext<Vue>) {
  if (resp instanceof TypeError || resp instanceof Error) {
    //@ts-ignore
    if (resp.response.status !== 401) {
      next({ path: ROUTE_PATHS_STATIC.PAGE_FORBBIDEN })
    }
    return false
  }
}

function checkInitialSystemSettings(setting: any, next: NavigationGuardNext<Vue>) {
  if (setting instanceof TypeError || setting instanceof Error) {
    //@ts-ignore
    if (setting.response.status !== 401) {
      next({ path: ROUTE_PATHS_STATIC.PAGE_FORBBIDEN })
    }
    return false
  }
}

function checkUserAccountSettings(userSessionSettings: any, next: NavigationGuardNext<Vue>) {
  if (userSessionSettings instanceof TypeError || userSessionSettings instanceof Error) {
    const error = userSessionSettings as AxiosError
    //@ts-ignore
    if (error.response.status !== 401) {
      next({ path: ROUTE_PATHS_STATIC.PAGE_FORBBIDEN })
    }
    return false
  }
}

function userSessionSettingsFunction(userSessionSettings: any) {
  if (userSessionSettings && userSessionSettings.length) {
    setUserAccountSettings(userSessionSettings[0])

    if (
      window.location.origin + process.env.VUE_APP_ADMIN_PATH === window.location.href ||
      window.location.origin + '/' === window.location.href
    ) {
      goToHomapage()
    }
    i18n.locale = store.getters['settings/systemLanguage']
    localStorageHandler('set', false, LOCAL_STORAGE_KEYS.LOCALE, null, store.getters['settings/systemLanguage'])
    vuetify.framework.theme.dark =
      localStorageHandler('get', false, LOCAL_STORAGE_KEYS.DARK_THEME) ??
      store.getters['settings/systemTheme'] === 'dark'
        ? true
        : false
    localStorageHandler('set', false, LOCAL_STORAGE_KEYS.DARK_THEME, null, vuetify.framework.theme.dark)

    const bufferTheme = localStorageHandler('get', false, LOCAL_STORAGE_KEYS.VUETIFY_CONFIG)
    bufferTheme.theme.dark = vuetify.framework.theme.dark

    const userSettingsValue = JSON.parse(userSessionSettings[0].value)
    setSelectedTheme(userSettingsValue, bufferTheme)

    localStorageHandler('set', false, LOCAL_STORAGE_KEYS.VUETIFY_CONFIG, null, bufferTheme)
  }
}

function setSelectedTheme(userSettingsValue: any, bufferTheme: any) {
  let selectedTheme
  if (userSettingsValue?.currentTheme) {
    selectedTheme = userSettingsValue?.currentTheme
    localStorageHandler('set', false, 'selectedTheme', null, selectedTheme)
  } else {
    if (vuetify.framework.theme.dark) {
      selectedTheme = {
        name: 'dark',
        colors: bufferTheme.theme.themes.dark,
        description: 'Темная тема',
      }
    } else {
      selectedTheme = {
        name: 'light',
        colors: bufferTheme.theme.themes.light,
        description: 'Светлая тема',
      }
    }
    localStorageHandler('set', false, 'selectedTheme', null, selectedTheme)
  }
}

function isInitialSettingsReceived() {
  return store.getters['settings/isInitialSystemSettingsReceived']
}
function isFailedToReceiveInitialSystemSettings() {
  return store.getters['settings/isFailedToReceiveInitialSystemSettings']
}
async function getInitialSystemSettings() {
  return await store.dispatch('settings/getInitialSystemSettings')
}
async function getAuditDictionary() {
  await store.dispatch('dictionary/getAuditDictionary')
}
function isAuthenticated() {
  return store.getters['auth/isAuthenticated']
}
async function checkSessionData() {
  await store.dispatch('auth/checkSessionData')
}
async function checkTokenExpiry() {
  await store.dispatch('auth/checkTokenExpiry')
}
async function setUnresolvedInitialNavigationPath(path: RouteDescription['path']) {
  store.commit('ui/setUnresolvedInitialNavigationPath', path)
}
function isUserPermissionsReceived() {
  return store.getters['auth/isUserPermissionsReceived']
}
async function checkUserPermissions() {
  await store.dispatch('auth/checkUserPermissions')
}
function getUserPermissions() {
  return store.getters['auth/userPermissions']
}
async function getUserInfo() {
  if (!checkUrlLocation()) {
    return await store.dispatch('usersInfo/getUserInfo')
  } else {
    return false
  }
}

async function initializationFinishedCallback() {
  await store.dispatch('services/initializationFinishedCallback')
}

async function fetchUserAccountSettings() {
  return store.dispatch('settings/fetchUserAccountSettings')
}

async function setUserAccountSettings(data: UserSettingDto) {
  const parsed = JSON.parse(data.value)

  return store.dispatch('settings/setUserAccountSettings', parsed)
}

function goToHomapage() {
  const homepage = store.getters['settings/systemHomepage']
  if (homepage) {
    navigate({ path: homepage, query: { updateRootTab: 'true' } })
  }
}

export default router
