var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{staticClass:"settings-theme",attrs:{"dense":""},on:{"click":function($event){_vm.isSelectedTheme = true}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-palette-outline")])],1),_c('v-list-item-title',{staticClass:"dh-text-body1"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('buttons.theme')))]),_c('span',{staticClass:"settings-theme--clickable-area"},[_vm._v(_vm._s(_vm.currentThemeTitle))])]),_c('dh-dialog',{attrs:{"model":_vm.isSelectedTheme,"agree-button-content":_vm.$t('buttons.chooseTheme'),"cancel-button-content":_vm.$t('buttons.cancelChooseTheme'),"width":"80%"},on:{"update:model":function($event){_vm.isSelectedTheme=$event},"agree-clicked":function($event){return _vm.chooseTheme(_vm.selectedTheme[0].name)},"cancel-clicked":function($event){_vm.isSelectedTheme = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dialogs.selectThemeTitle'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('dh-workspace-area-actions-wrapper',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('dh-button',{staticClass:"dh-workspace-area-actions__refresh",attrs:{"button-type":"icon","tooltip-content":_vm.$t('common.buttons.refresh')},on:{"click":function($event){return _vm.getThemesData(_vm.pagination)}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]},proxy:true}])}),_c('div',[_c('dh-data-table',{staticClass:"themes__table",attrs:{"items":_vm.themes,"loading":_vm.isThemesListLoading,"headers":_vm.tableHeaders,"options":_vm.pagination,"saved-searches":false,"full-text-search":false,"single-select":"","show-select":""},on:{"update:headers":function($event){_vm.tableHeaders=$event},"update:options":[function($event){_vm.pagination=$event},_vm.getThemesData],"run-search":function($event){_vm.pagination.page = 1
            _vm.pagination.searchDto = $event
            _vm.getThemesData(_vm.pagination)}},scopedSlots:_vm._u([{key:"item.colors",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"flex row",style:({ gap: '20px' })},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({style:({ height: '25px', width: '25px', backgroundColor: item.colors.primary })},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('themes.attributes.mainColor')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({style:({ height: '25px', width: '25px', backgroundColor: item.colors.secondary })},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('themes.attributes.secondaryColor')))])])],1)]}}]),model:{value:(_vm.selectedTheme),callback:function ($$v) {_vm.selectedTheme=$$v},expression:"selectedTheme"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }