import api from '@/api/api'
import i18n from '@/plugins/i18n'
import systemSettings from '../../../public/system-settings.json'
import _isEmpty from 'lodash.isempty'
import { localStorageHandler } from '@docshouse/dh-ui-components'
import {
  getServiceRootComponent,
  showConsoleError,
  fixRootComponent,
  convertServicesArray,
  shallowEqualIgnoreNull,
} from '@/common/utils'
import { ActionContext, Commit, Dispatch } from 'vuex'
import {
  InitialSystemSettings,
  LOCAL_STORAGE_KEYS,
  RegistryApp,
  RegistryDto,
  RegistryUiComponent,
  RequestPageParams,
  SERVICE_NAMES,
  ServiceComponent,
  ServiceDescriptionDto,
  ServiceName,
  SnackBarMessage,
  UserSessionSettings,
  UserSettingCreateRequestDto,
  UserSettingDto,
} from '@docshouse/dh-ui-types'
import axios, { AxiosError } from 'axios'
import { DEFAULT_ACCOUNT_SETTINGS } from '@/common/constants'

interface SettingsState {
  registryAppsDescription: RegistryApp[]
  initialSystemSettings: InitialSystemSettings | Record<string, never>
  isFailedToReceiveInitialSystemSettings: boolean
  isEmbeddedInIframe: boolean
  iframeOptions: Record<string, string> | null
  r7Options: boolean
  profile: string | null
  theme: string | null
  currentTheme: string | null
  language: string | null
  homepage: string | null
  isUserSettingChange: boolean
  areAnySettingsToReset: boolean
}

const getDefaultState = () => {
  return {
    registryAppsDescription: [],
    initialSystemSettings: {},
    isFailedToReceiveInitialSystemSettings: false,
    isEmbeddedInIframe: false,
    iframeOptions: null,
    r7Options: false,
    profile: null,
    theme: 'light',
    currentTheme: null,
    language: 'ru',
    homepage: null,
    isUserSettingChange: false,
    areAnySettingsToReset: true,
  } as SettingsState
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    registryAppsDescription(state: SettingsState) {
      return state.registryAppsDescription
    },
    initialSystemSettings(state: SettingsState) {
      return state.initialSystemSettings
    },
    isInitialSystemSettingsReceived(state: SettingsState) {
      return !!state.initialSystemSettings.services
    },
    isFailedToReceiveInitialSystemSettings(state: SettingsState) {
      return state.isFailedToReceiveInitialSystemSettings
    },
    isRegistryEnabled(state: SettingsState) {
      return state.initialSystemSettings?.ui?.isRegistryEnabled
    },
    systemName(state: SettingsState) {
      return state.initialSystemSettings.systemName
    },
    isEmbeddedInIframe(state: SettingsState) {
      return state.isEmbeddedInIframe
    },
    iframeOptions(state: SettingsState) {
      return state.iframeOptions
    },
    r7Options(state: SettingsState) {
      return state.r7Options
    },
    userProfile(state: SettingsState) {
      return state.profile
    },
    systemTheme(state: SettingsState) {
      return state.theme
    },
    systemCurrentTheme(state: SettingsState) {
      return state.currentTheme
    },
    systemLanguage(state: SettingsState) {
      return state.language
    },
    systemHomepage(state: SettingsState) {
      return state.homepage
    },
    isUserSettingChange(state: SettingsState) {
      return state.isUserSettingChange
    },
    areAnySettingsToReset(state: SettingsState) {
      return state.areAnySettingsToReset
    },
  },

  mutations: {
    setRegistryAppsDescription(state: SettingsState, payload: RegistryApp[]) {
      state.registryAppsDescription = payload
    },

    setInitialSystemSettings(state: SettingsState, settings: InitialSystemSettings) {
      state.initialSystemSettings = settings
    },

    setIsFailedToReceiveInitialSystemSettings(state: SettingsState, payload: boolean) {
      state.isFailedToReceiveInitialSystemSettings = payload
    },

    resetState(state: SettingsState) {
      Object.assign(state, getDefaultState())
    },

    setIsEmbeddedInIframe(state: SettingsState, payload: boolean) {
      state.isEmbeddedInIframe = payload
    },

    setIframeOptions(state: SettingsState, payload: SettingsState['iframeOptions']) {
      state.iframeOptions = payload
    },

    setR7Options(state: SettingsState, payload: SettingsState['r7Options']) {
      state.r7Options = payload
    },

    setUserAccountSettings(state: SettingsState, payload: UserSessionSettings) {
      state.profile = payload.profile || null
      state.theme = payload.theme || 'light'
      state.language = payload.language || 'ru'
      state.homepage = payload.homepage || null
    },

    setUserProfile(state: SettingsState, profile: string | null) {
      state.profile = profile
    },
    setSystemTheme(state: SettingsState, theme: string) {
      state.theme = theme
    },
    setSystemCurrentTheme(state: SettingsState, currentTheme: string) {
      state.currentTheme = currentTheme
    },
    setSystemLanguage(state: SettingsState, locale: string) {
      state.language = locale
    },
    setSystemHomepage(state: SettingsState, homepage: string | null) {
      state.homepage = homepage
    },
    setUserSettingChangeStatus(state: SettingsState, status: boolean) {
      state.isUserSettingChange = status
    },
    setAnySettingsToReset(state: SettingsState, status: boolean) {
      state.areAnySettingsToReset = status
    },
  },

  actions: {
    async getInitialSystemSettings({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }): Promise<any> {
      try {
        let systemSettingsLocal = systemSettings as InitialSystemSettings

        const systemSettingsLocalCashed = localStorageHandler('get', false, LOCAL_STORAGE_KEYS.SYSTEM_SETTINGS_DATA)
        if (systemSettingsLocalCashed) {
          systemSettingsLocal = systemSettingsLocalCashed
        } else {
          localStorageHandler('set', false, LOCAL_STORAGE_KEYS.SYSTEM_SETTINGS_DATA, null, systemSettingsLocal)
        }
        const isRegistryEnabled = systemSettingsLocal.ui.isRegistryEnabled

        let servicesSettingsRemote = {}
        if (isRegistryEnabled && !window.location.href.includes('/login?error')) {
          const response = await api.registry.getAllRegistryDataWithUi()

          servicesSettingsRemote = convertServicesArray(response.data)
          // Оставляем только один рутовый компонет все остальные удаляем
          fixRootComponent(servicesSettingsRemote)
          commit('setRegistryAppsDescription', servicesSettingsRemote)
          localStorageHandler('set', false, LOCAL_STORAGE_KEYS.REGISTRY_DATA, null, servicesSettingsRemote)
        }

        // преобразуем структуру, полученную из registry service к структуре с которой может работать фронт
        const initialSystemSettings: InitialSystemSettings = await dispatch(
          'convertRegistryAppsDtoToInitialSystemSettings',
          { systemSettingsLocal, servicesSettingsRemote }
        )
        commit('setInitialSystemSettings', initialSystemSettings)

        // формируем в стейте объект для более удобной работы со списком сервисов
        initialSystemSettings.services?.forEach((service) => {
          dispatch('services/setService', service, { root: true })
          dispatch('services/registerRoute', service, { root: true })
        })
        // устанавливаем название вкладки в соответствии с полученным названием системы
        document.title = initialSystemSettings.systemName
        return true
      } catch (error) {
        const message = (error as Error)?.message ?? i18n.t('notifications.getInitialSystemSettingsError')
        commit('setIsFailedToReceiveInitialSystemSettings', true)
        dispatch(
          'events/showMessage',
          {
            payload: {
              message,
              color: 'error',
            } as SnackBarMessage,
          },
          { root: true }
        )
        showConsoleError({ message, error })
        return error
      }
    },

    async convertRegistryAppsDtoToInitialSystemSettings(
      { dispatch }: { dispatch: Dispatch },
      {
        systemSettingsLocal,
        servicesSettingsRemote,
      }: {
        systemSettingsLocal: InitialSystemSettings
        servicesSettingsRemote: RegistryDto
      }
    ): Promise<InitialSystemSettings> {
      const resultSystemSettings: InitialSystemSettings = {
        systemName: systemSettingsLocal.systemName,
        ui: systemSettingsLocal.ui,
        services: [],
      }

      const convertComponents = (components: RegistryUiComponent[]) => {
        return components.map((component) => {
          const convertedComponent: ServiceComponent = {
            ...component,
            properties: component.properties,
            meta: component.metadata,
            components: component.components ? convertComponents(component.components) : undefined,
          }
          return convertedComponent
        })
      }

      const convertedServicesSettingsArrayRemote = Object.values(servicesSettingsRemote)
        .filter((app) => !!app?.appDescriptor.metadata.applicationSupportUi)
        .sort((a, b) => {
          const orderA = a.appDescriptor.ui.components[0].menuOrder ?? Infinity
          const orderB = b.appDescriptor.ui.components[0].menuOrder ?? Infinity
          return orderA - orderB
        })
        .map((app) => {
          try {
            return {
              name: app?.appDescriptor.name,
              description: app?.appDescriptor.description,
              version: app?.appDescriptor.build.version,
              components: convertComponents(app?.appDescriptor.ui?.components),
              permissions: [],
            } as ServiceDescriptionDto
          } catch (error) {
            const message = 'Failed to convert the settings received from the registry service'
            showConsoleError({ message, error, serviceName: app.appDescriptor.name })
            return {
              name: app?.appDescriptor.name,
              version: '',
              description: '',
              permissions: [],
              components: [
                {
                  id: '',
                  name: '',
                  type: 'web-component-root',
                  src: '',
                  icon: '',
                  meta: { isDisabled: true, initializationDetails: message },
                  menuOrder: 0,
                },
              ],
            } as ServiceDescriptionDto
          }
        })

      const servicesSettingsMapLocal = systemSettingsLocal.services?.reduce((acc, curr) => {
        acc[curr.name] = curr
        return acc
      }, {} as Record<ServiceName, ServiceDescriptionDto>)
      const servicesSettingsMapRemote = convertedServicesSettingsArrayRemote.reduce((acc, curr) => {
        acc[curr.name] = curr
        return acc
      }, {} as Record<ServiceName, ServiceDescriptionDto>)

      resultSystemSettings.services = await dispatch('mergeRemoteAndLocalServicesSettings', {
        servicesSettingsMapRemote,
        servicesSettingsMapLocal,
        isDynamicServicesEnabled: resultSystemSettings.ui.isDynamicServicesEnabled,
      })

      return resultSystemSettings
    },

    mergeRemoteAndLocalServicesSettings(
      ctx: ActionContext<SettingsState, any>,
      {
        servicesSettingsMapRemote,
        servicesSettingsMapLocal,
        isDynamicServicesEnabled,
      }: {
        servicesSettingsMapRemote: Record<ServiceName, ServiceDescriptionDto>
        servicesSettingsMapLocal: Record<ServiceName, ServiceDescriptionDto>
        isDynamicServicesEnabled: InitialSystemSettings['ui']['isDynamicServicesEnabled']
      }
    ): ServiceDescriptionDto[] {
      const resultServicesSettings: ServiceDescriptionDto[] = []

      const serviceNames = new Set([
        ...Object.keys(servicesSettingsMapRemote),
        ...Object.keys(servicesSettingsMapLocal),
      ])

      serviceNames.forEach((name) => {
        const remoteServiceSettings = servicesSettingsMapRemote[name]
        const remoteRootComponent = remoteServiceSettings ? getServiceRootComponent(remoteServiceSettings)! : null
        const localServiceSettings = servicesSettingsMapLocal[name]
        const localRootComponent = localServiceSettings ? getServiceRootComponent(localServiceSettings)! : null
        const isDynamicService = remoteRootComponent?.meta?.isDynamicService
        const isServiceIncludedInLocalSettings = !!localServiceSettings
        const childrenComponents = isServiceIncludedInLocalSettings
          ? localRootComponent?.components
          : remoteRootComponent?.components

        const isServiceUseLocalSettings = !!localRootComponent?.meta?.isLocalSettings
        const isServiceDisabledManually = !!localRootComponent?.meta?.isDisabled

        // определяем должен сервис использовать локальные настройки или удаленные
        const mergedServiceSettings =
          isServiceIncludedInLocalSettings && isServiceUseLocalSettings
            ? { ...localServiceSettings }
            : { ...remoteServiceSettings }

        if (_isEmpty(mergedServiceSettings)) {
          return
        }

        const mergedRootComponent = getServiceRootComponent(mergedServiceSettings)!

        // объединяем объекты meta из удаленных и локальных настроек
        const isStaticServiceNotIncludedInLocalSettings = !isDynamicService && !isServiceIncludedInLocalSettings
        const isDynamicServiceDisabledGlobally = isDynamicService && !isDynamicServicesEnabled
        mergedRootComponent.components = childrenComponents?.length ? childrenComponents : []
        mergedRootComponent.meta = {
          ...(remoteRootComponent?.meta || {}),
          ...localRootComponent?.meta,
          isDynamicServiceCustomBundle:
            mergedRootComponent?.meta?.isDynamicService &&
            !mergedRootComponent.src?.includes(SERVICE_NAMES.DYNAMIC_SERVICE),
          isDynamicObjectsService: mergedRootComponent?.src?.includes(SERVICE_NAMES.OBJECTS_SERVICE),
          // отключаем сервисы в следующих случаях:
          // - корневой компонент сервиса отключен вручную через локальные настройки
          // - сервис статический и не включен в локальные настройки
          // - сервис динамический (не может присутствовать в локальных настройках), но динамические сервисы отключены полностью
          isDisabled:
            isServiceDisabledManually || isStaticServiceNotIncludedInLocalSettings || isDynamicServiceDisabledGlobally,
        }

        resultServicesSettings.push(mergedServiceSettings)
      })

      return resultServicesSettings
    },

    async fetchUserAccountSettings({ dispatch, rootGetters }: { dispatch: Dispatch; rootGetters: any }): Promise<any> {
      try {
        const params = {
          container: rootGetters['usersInfo/getUserInfo']?.preferredUsername ?? 'user',
          containerType: 'USER',
          page: 0,
          size: 999,
        }
        const response = await api.usersSettingsApi.getCurrentUserSettings(params)
        return response.data
      } catch (error) {
        const err = error as AxiosError
        console.log(err.response)
        if (axios.isAxiosError(error) && error.message) {
          dispatch('events/showMessage', { payload: { message: error.message, color: 'error' } }, { root: true })
        }
        return err
        //throw error
      }
    },

    async createSetting(
      ctx: ActionContext<SettingsState, any>,
      creationPayload: UserSettingCreateRequestDto
    ): Promise<UserSettingDto> {
      const response = await api.usersSettingsApi.createSetting(creationPayload)

      return response.data
    },

    async updateSettingById(
      ctx: ActionContext<SettingsState, any>,
      payload: {
        id: UserSettingDto['id']
        data: UserSettingCreateRequestDto
      }
    ): Promise<UserSettingDto> {
      const response = await api.usersSettingsApi.updateSettingById(payload.id, payload.data)

      return response.data
    },

    async deleteSettingById(ctx: ActionContext<SettingsState, any>, id: UserSettingDto['id']): Promise<UserSettingDto> {
      const response = await api.usersSettingsApi.deleteSettingById(id)

      return response.data
    },

    async deleteCurrentUserSetting(ctx: ActionContext<SettingsState, any>, params: any): Promise<UserSettingDto[]> {
      const response = await api.usersSettingsApi.deleteCurrentUserSetting(params)

      return response.data
    },

    async getCurrentUserSettingsList(
      ctx: ActionContext<SettingsState, any>,
      params: RequestPageParams<UserSettingDto>
    ): Promise<UserSettingDto[]> {
      const response = await api.usersSettingsApi.getCurrentUserSettings(params)

      return response.data
    },

    setUserAccountSettings(ctx: ActionContext<SettingsState, any>, payload: any): void {
      ctx.commit('setUserAccountSettings', payload)
    },

    async checkUserSettingsForResetAvaiable(ctx: ActionContext<SettingsState, any>): Promise<void> {
      const currentSettings = {
        language: ctx.getters.systemLanguage,
        theme: ctx.getters.systemTheme,
        homepage: ctx.getters.systemHomepage,
        profile: ctx.getters.userProfile,
      }
      const isSettingsEqualOrEmpty =
        shallowEqualIgnoreNull(currentSettings, DEFAULT_ACCOUNT_SETTINGS) || Object.keys(currentSettings).length === 0

      const settings = await ctx.dispatch('getCurrentUserSettingsList', { containerType: 'COMPONENT' })
      ctx.commit('setAnySettingsToReset', !isSettingsEqualOrEmpty || settings.length > 0)
    },
  },
}
