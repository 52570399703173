





















































































import { DhButton } from '@docshouse/dh-ui-components'
import axios from 'axios'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SettingsProfile',

  data() {
    return {
      isServicesProfileDialogOpen: false,
      tableHeaders: [{ text: 'profile', value: 'name', width: '100%' }],
      tableSearch: '',
      listUsers: [],
      isLoading: false,
      isDisableAgreeButton: false,
      isDataLoading: false,
      selectedUser: [] as any[],
    }
  },

  computed: {
    ...mapGetters({
      userInfo: 'usersInfo/getUserInfo',
    }),
    isSelectProfile(): any {
      return !!this.userInfo?.activeProfile?.preferredUsername
    },
    textButton(): any {
      return this.isSelectProfile
        ? this.userInfo?.activeProfile?.preferredUsername
          ? this.userInfo?.activeProfile?.preferredUsername
          : this.$t('common.buttons.select')
        : this.$t('common.buttons.select')
    },
  },

  async created() {
    try {
      const result = await this.getUserProfiles({ params: { fields: 'id,name' } })
      if (result.length) {
        this.listUsers = result.map((item: any) => {
          return {
            ...item,
            name: item.name.replace('null', ''),
          }
        })
      }
    } catch (error) {
      console.log(error)
      if (axios.isAxiosError(error) && error.message) {
        this.showMessage({ payload: { message: error.message, color: 'error' } })
      }
    }
  },

  methods: {
    ...mapActions({
      getUserProfiles: 'usersInfo/getUserProfiles',
      switchUserProfile: 'gateway/switchUserProfile',
      exitUserProfile: 'gateway/exitUserProfile',
      showMessage: 'events/showMessage',
      getUserInfo: 'usersInfo/getUserInfo',
      getUserPermissions: 'auth/getUserPermissions',
    }),

    async getUserData() {
      this.isDataLoading = true
      try {
        const result = await this.getUserProfiles({ params: { fields: 'id,name' } })
        if (result.length) {
          this.listUsers = result.map((item: any) => {
            return {
              ...item,
              name: item.name.replace('null', ''),
            }
          })
        }
      } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error) && error.message) {
          this.showMessage({ message: error.message, color: 'error' })
        }
      } finally {
        this.isDataLoading = false
      }
    },
    openServicesProfileDialog() {
      this.isServicesProfileDialogOpen = true
    },

    closeServicesProfileDialog() {
      this.isServicesProfileDialogOpen = false
      this.selectedUser = []
    },

    async exitServicesProfileHandler() {
      try {
        await this.exitUserProfile()
        this.showMessage({
          payload: {
            message: this.$t('notifications.exitProfileSuccess', {
              name: this.userInfo?.activeProfile?.preferredUsername
                ? this.userInfo?.activeProfile?.preferredUsername
                : this.userInfo?.preferredUsername,
            }),
            color: 'success',
          },
        })
        await this.getUserInfo()

        await this.getUserPermissions()
        location.reload()
      } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error) && error.message) {
          this.showMessage({ payload: { message: error.message, color: 'error' } })
        }
      } finally {
        this.closeServicesProfileDialog()
      }
    },

    async selectUserHandler(item: any) {
      try {
        const payload = {
          userProfileId: item.id,
        }
        await this.switchUserProfile(payload)
        await this.getUserInfo()

        await this.getUserPermissions()

        this.showMessage({
          payload: {
            message: this.$t('notifications.selectProfileSuccess', {
              name: item?.name,
            }),
            color: 'success',
          },
        })
        location.reload()
      } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error) && error.message) {
          this.showMessage({ payload: { message: error.message, color: 'error' } })
        }
      } finally {
        this.closeServicesProfileDialog()
      }
    },
  },
})
