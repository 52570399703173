import api from '@/api/api'
import axios from 'axios'
import { InitDictDB } from '@docshouse/dh-ui-components'
import { Commit, Dispatch } from 'vuex'

export default {
  namespaced: true,

  state: {
    isDictionaryLoading: false,
  },

  getters: {
    isLoading(state: any) {
      return state.isDictionaryLoading
    },
  },

  mutations: {
    setDictionaryLoading(state: any, payload: boolean) {
      state.isDictionaryLoading = payload
    },
  },

  actions: {
    async getAuditDictionary({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      try {
        commit('setDictionaryLoading', true)
        const response = await api.registry.searchApplicationsByRootClassTypes({
          rootClassType: 'EVENT',
          fields: 'name, description, appDescriptor, classDefinitions, classDefinitions.**,',
        })
        const arrayBuff = []
        for (const item of response.data) {
          for (const prop of item.classDefinitions) {
            if (prop.rootClassType === 'EVENT') {
              const obj = {
                key: item.name + '::' + prop.code,
                name: prop.name,
                code: prop.code,
                serviceName: item.name,
                description: item.description,
              }
              arrayBuff.push(obj)
            }
          }
        }
        await InitDictDB.pushItems('audit-event', process.env.VUE_APP_DB_NAME, arrayBuff, 'key')
      } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error) && error.message) {
          dispatch('events/showMessage', { payload: { message: error.message, color: 'error' } }, { root: true })
        }
      } finally {
        commit('setDictionaryLoading', false)
      }
    },
  },
}
