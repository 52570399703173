import AxiosInstance from '@/api/axios-instance'
import auth from '@/api/modules/auth.api'
import services from '@/api/modules/services.api'
import settings from '@/api/modules/settings.api'
import gateway from '@/api/modules/gateway.api'
import { FacadeApi } from '@docshouse/dh-facade-utils-ui'
import { RegistryApi, UserSettingsApi, UsersInfoApi } from '@docshouse/dh-ui-types'

const facade = new FacadeApi(AxiosInstance)
const registry = new RegistryApi(AxiosInstance)
const usersInfo = new UsersInfoApi(AxiosInstance)
const usersSettingsApi = new UserSettingsApi(AxiosInstance)

export default {
  auth,
  services,
  gateway,
  settings,
  registry,
  facade,
  usersInfo,
  usersSettingsApi,
}
