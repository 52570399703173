














import Vue from 'vue'

export default Vue.extend({
  name: 'LoginError',

  data() {
    return {
      dialog: true,
    }
  },

  methods: {
    goNaher() {
      const params = new URLSearchParams(window.location.search)

      if (params.has('successAuthUri')) {
        const successAuthUri = params.get('successAuthUri')
        window.location.href = successAuthUri!
      }
    },
  },
})
