import api from '@/api/api'
import { ActionContext } from 'vuex'
import { RequestPageParams } from '@docshouse/dh-ui-types'
import { ThemeDto } from '@docshouse/dh-facade-utils-ui'

type ThemeState = Record<string, never>

export default {
  namespaced: true,

  state: {} as ThemeState,

  getters: {},

  mutations: {},

  actions: {
    async getThemes(ctx: ActionContext<ThemeState, any>, params: RequestPageParams<ThemeDto>): Promise<ThemeDto[]> {
      const response = await api.facade.themes.getThemes({ params })
      return response.data
    },
  },
}
