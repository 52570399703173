


























































































































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _throttle from 'lodash.throttle'
import { getServiceNameByPath } from '@/common/utils'
import { LOCAL_STORAGE_KEYS, RouteDescription, Tab } from '@docshouse/dh-ui-types'
import SettingsNotifications from '@/components/settings/SettingsNotifications.vue'
import SettingsAccount from '@/components/settings/SettingsAccount.vue'
import SearchFieldWrapper from '@/components/search/SearchFieldWrapper.vue'
import TabHomepageMenu from '@/components/common/TabHomepageMenu.vue'
import TabSearchMenu from './TabSearchMenu.vue'

export default Vue.extend({
  name: 'AppBar',
  components: {
    SearchFieldWrapper,
    SettingsNotifications,
    SettingsAccount,
    TabHomepageMenu,
    TabSearchMenu,
  },

  data() {
    return {
      tabReadyToClose: null as Tab | null,
      targetReadyForRedirect: null as Tab | RouteDescription['path'] | null,
      isScrollTabButtonsVisible: false,
      isScrollTabLeftButtonDisabled: false,
      isScrollTabRightButtonDisabled: false,
      isConfirmCloseTabDialogOpen: false,
      isConfirmCloseAllTabsDialogOpen: false,
      isSearchFieldVisible: false,
      currentFullPath: '',
    }
  },

  computed: {
    ...mapGetters({
      isServiceActive: 'services/isServiceActive',
      tabs: 'ui/tabs',
      activeTab: 'ui/activeTab',
      activeTabIndex: 'ui/activeTabIndex',
      isDrawerMini: 'ui/isDrawerMini',
      unresolvedBackButtonNavigationPath: 'ui/unresolvedBackButtonNavigationPath',
      isSearchAvailable: 'ui/isSearchAvailable',
      isNotificationsInBackgroundAvailable: 'ui/isNotificationsInBackgroundAvailable',
      isEmbeddedInIframe: 'settings/isEmbeddedInIframe',
      systemHomepage: 'settings/systemHomepage',
    }),
    rootTab(): Tab {
      return this.tabs[0]
    },
    tabsExcludingRoot(): Tab[] {
      return this.tabs.filter((tab: Tab) => tab.id !== 'root')
    },
    activeTabIndexInScrollContainer(): number | null {
      // индекс активной вкладки внутри scroll контейнера вычисляется с учетом того,
      // что root вкладка при рендере не находятся в итерируемом массиве
      return this.tabs.length > 1 ? this.activeTabIndex - 1 : null
    },
    isCloseAllTabsButtonVisible(): boolean {
      return this.tabs.length > 1
    },
    throttle(): typeof _throttle {
      return _throttle
    },
    isSettingsNotificationsVisible(): boolean {
      return this.isNotificationsInBackgroundAvailable && !this.isEmbeddedInIframe
    },
    isSettingsAccountVisible(): boolean {
      return !this.isEmbeddedInIframe
    },

    isCurrentPageHome(): boolean {
      return this.activeTab?.path === this.systemHomepage
    },
  },

  watch: {
    tabs: {
      deep: true,
      handler: 'saveTabsToLocalStorage',
    },
    'tabs.length': {
      handler: function () {
        setTimeout(() => {
          this.updateTabScrollButtonsVisibility()
        })
      },
    },
    activeTabIndex: {
      handler: function (index: number) {
        if (typeof this.activeTabIndexInScrollContainer === 'number') {
          setTimeout(() => {
            if (typeof this.activeTabIndexInScrollContainer === 'number') {
              const position = this.getScrollLogicalPosition(index, this.tabs.length)
              this.autoScrollTabsToActive(position)
            }
          })
        }
      },
    },
    isDrawerMini: {
      handler: function () {
        if (this.activeTabIndexInScrollContainer) {
          setTimeout(() => {
            this.autoScrollTabsToActive()
          })
        }
      },
    },
    unresolvedBackButtonNavigationPath: {
      // случай, когда сервис сообщил о нажатии юзером кнопки "назад".
      // переход обрабатывается в ui-base, а не в сервисе, так как логика подтверждения закрытия dirty вкладки находится только в ui-base.
      // при нажатии кнопки "назад" текущую вкладку необходимо закрыть (в соответствии с требованиями ux)
      handler: function (targetPath: RouteDescription['path'] | null) {
        if (targetPath) {
          this.closeTabHandler(this.activeTab, targetPath)
        }
      },
    },
    isSearchFieldVisible: {
      handler: function () {
        setTimeout(() => {
          const position = this.getScrollLogicalPosition(this.activeTabIndex, this.tabs.length)
          this.autoScrollTabsToActive(position)
        })
      },
    },
  },

  created() {
    this.restoreTabsFromLocalStorage()

    if (this.isEmbeddedInIframe) {
      this.resolveTabInIFrame()
    }
  },

  methods: {
    ...mapActions({
      showMessage: 'events/showMessage',
      closeTab: 'ui/closeTab',
      closeAllTabs: 'ui/closeAllTabs',
      updateTab: 'ui/updateTab',
      resetActiveTab: 'ui/resetActiveTab',
      restoreTabs: 'ui/restoreTabs',
    }),
    ...mapMutations({
      setUnresolvedBackButtonNavigationPath: 'ui/setUnresolvedBackButtonNavigationPath',
    }),

    navigateToTab(tab: Tab) {
      if (!tab.isActive) {
        this.resetActiveTab()
      }

      const isNavigateToSameService = this.isServiceActive(tab.source)
      this.$navigate({ path: tab.path }, isNavigateToSameService)

      if (tab.id === 'root') {
        this.updateTab({ id: 'root', isActive: true })
      }
    },

    navigateToRedirectTarget(redirectTarget?: Tab | RouteDescription['path'] | null) {
      // если зафиксирована вкладка или путь, куда должен быть выполнен переход после закрытия текущей вкладки, то переходим туда.
      // иначе - на ближайшую левую вкладку
      if (redirectTarget && typeof redirectTarget === 'string') {
        // переход на конкретный путь
        const targetServiceName = getServiceNameByPath(redirectTarget)
        const isTargetServiceActive = this.isServiceActive(targetServiceName)
        this.$navigate({ path: redirectTarget, query: { updateRootTab: 'true' } }, isTargetServiceActive)
      } else if (redirectTarget && typeof redirectTarget === 'object') {
        // переход на конкретную вкладку
        this.navigateToTab(redirectTarget)
      } else {
        // переход на ближайшую левую вкладку
        const targetTab = this.getLeftTab(this.tabReadyToClose as Tab)
        this.navigateToTab(targetTab)
      }
    },

    closeTabHandler(tab: Tab, redirectTarget?: Tab | RouteDescription['path']) {
      if (tab.isDirty) {
        this.setTabReadyToClose(tab)
        if (redirectTarget) {
          this.setTargetReadyForRedirect(redirectTarget)
        }

        this.openConfirmCloseTabDialog()
      } else {
        if (tab.isActive) {
          this.navigateToRedirectTarget(redirectTarget ?? this.getLeftTab(tab))
        }
        this.closeTab(tab.id)
      }

      if (this.unresolvedBackButtonNavigationPath) {
        this.setUnresolvedBackButtonNavigationPath(null)
      }
    },

    confirmCloseTabHandler() {
      if (this.tabReadyToClose?.isActive) {
        this.navigateToRedirectTarget(this.targetReadyForRedirect)
      }

      this.closeTab(this.tabReadyToClose?.id)
      this.resetTabReadyToClose()

      if (this.targetReadyForRedirect) {
        this.resetTargetReadyForRedirect()
      }
      this.closeConfirmCloseTabDialog()
    },

    cancelCloseTabHandler() {
      this.closeConfirmCloseTabDialog()
    },

    closeAllTabsHandler() {
      const isSomeTabsDirty = this.tabs.some((tab: Tab) => tab.isDirty)

      if (isSomeTabsDirty) {
        this.openConfirmCloseAllTabsDialog()
      } else {
        this.closeAllTabs()
      }
    },

    confirmCloseAllTabsHandler() {
      this.navigateToTab(this.rootTab)
      this.closeAllTabs()
      this.closeConfirmCloseAllTabsDialog()
    },

    cancelCloseAllTabsHandler() {
      this.closeConfirmCloseAllTabsDialog()
    },

    getLeftTab(currentTab: Tab): Tab {
      const currentTabIndex = this.tabs.findIndex((tab: Tab) => tab.id === currentTab.id)
      return this.tabs[currentTabIndex - 1]
    },

    setTabReadyToClose(tab: Tab) {
      this.tabReadyToClose = tab
    },

    resetTabReadyToClose() {
      this.tabReadyToClose = null
    },

    setTargetReadyForRedirect(target: Tab | RouteDescription['path']) {
      this.targetReadyForRedirect = target
    },

    resetTargetReadyForRedirect() {
      this.targetReadyForRedirect = null
    },

    scrollTabsButtonHandler(direction: 'left' | 'right', distance?: number) {
      const tabsScrollContainerEl: HTMLDivElement = this.$refs.tabsScrollContainerRef

      const tabItemWidth = 256
      const fullyVisibleTabsCount = Math.floor(tabsScrollContainerEl.clientWidth / tabItemWidth) || 1
      distance = distance ?? fullyVisibleTabsCount * tabItemWidth

      tabsScrollContainerEl.scrollLeft =
        direction === 'left' ? tabsScrollContainerEl.scrollLeft - distance : tabsScrollContainerEl.scrollLeft + distance
    },

    // нативное решение от vuetify v-tabs с автоматическим определением активной вкладки не подходит, так как vuetify
    // ориентируется на роут маршрутизатора ui-base, а за каждую вкладку отвечает собственный маршрутизатор соответствующего сервиса.
    // по этой причине логика вкладок реализована вручную
    autoScrollTabsToActive(position: any = 'nearest') {
      const tabsRef = this.$refs.tabsRef
      if (!tabsRef) {
        return
      }

      const activeTabEl: HTMLDivElement = tabsRef[this.activeTabIndexInScrollContainer!]
      if (!activeTabEl) {
        return
      }

      const scrollContainer: HTMLElement = activeTabEl.parentElement!
      if (position === 'end') {
        // scrollIntoView 'nearest' работает корректно во всех случаях, кроме последней вкладки (не хватает несколько пикселей до конца)
        // поэтому данный случай обрабатывается отдельно
        const targetScrollPosition = scrollContainer.scrollWidth - scrollContainer.clientWidth
        scrollContainer.scrollTo({ left: targetScrollPosition, behavior: 'smooth' })
      } else {
        activeTabEl.scrollIntoView({ behavior: 'smooth', inline: position })
      }
    },

    getScrollLogicalPosition(tabIndex: number, tabsLength: number): string {
      if (tabIndex === 1) {
        return 'start'
      } else if (tabIndex === tabsLength - 1) {
        return 'end'
      } else {
        return 'nearest'
      }
    },

    updateTabScrollButtonsVisibility() {
      const scrollContainerEl = this.$refs.tabsScrollContainerRef
      const tabsScrollContainerClientWidth = scrollContainerEl?.clientWidth
      const tabsScrollContainerScrollWidth = scrollContainerEl?.scrollWidth
      this.isScrollTabButtonsVisible = tabsScrollContainerScrollWidth > tabsScrollContainerClientWidth
    },

    updateTabScrollButtonsActivity() {
      const scrollContainerEl = this.$refs.tabsScrollContainerRef
      const scrollLeft = scrollContainerEl.scrollLeft
      this.isScrollTabLeftButtonDisabled = scrollLeft === 0
      this.isScrollTabRightButtonDisabled =
        Math.floor(scrollContainerEl.scrollWidth - scrollContainerEl.scrollLeft) === scrollContainerEl.clientWidth
    },

    onScrollHandler() {
      this.updateTabScrollButtonsActivity()
    },

    onResizeHandler() {
      this.updateTabScrollButtonsActivity()
      this.updateTabScrollButtonsVisibility()
    },

    saveTabsToLocalStorage(tabs: Tab[]) {
      this.$localStorageHandler('set', this.$baseAppName, LOCAL_STORAGE_KEYS.TABS, null, tabs)
    },

    restoreTabsFromLocalStorage() {
      const tabsFromLocalStorage = this.$localStorageHandler('get', this.$baseAppName, LOCAL_STORAGE_KEYS.TABS)
      if (tabsFromLocalStorage) {
        this.restoreTabs(tabsFromLocalStorage as Tab[])
      }
    },

    resolveTabInIFrame() {
      // решает проблемы с неверным определением активной табы при перезагрузке страницы с iframe.
      // пример проблемы:
      //  - для iframe установлен src = serviceName/collectionId
      //  - внутри iframe пользователь переходит на роут serviceName/collectionId/entityId
      //  - пользователь перезагружает страницу
      // iframe снова загрузится с исходным src, но ui-base об этом не знает и восстановит прошлую вкладку из local storage
      const targetTab = this.tabs.find((tab: Tab) => tab.path === location.pathname)
      if (targetTab) {
        this.navigateToTab(targetTab)
      }
    },

    openConfirmCloseTabDialog() {
      this.isConfirmCloseTabDialogOpen = true
    },

    closeConfirmCloseTabDialog() {
      this.isConfirmCloseTabDialogOpen = false
    },

    openConfirmCloseAllTabsDialog() {
      this.isConfirmCloseAllTabsDialogOpen = true
    },

    closeConfirmCloseAllTabsDialog() {
      this.isConfirmCloseAllTabsDialogOpen = false
    },

    goHomepage() {
      if (!this.isCurrentPageHome && this.systemHomepage) {
        const match = this.systemHomepage.match(/^\/([^/]+)/)
        const isNavigateToSameService = this.$route.path.includes(match[1])
        this.$navigate({ path: this.systemHomepage, query: { updateRootTab: 'true' } }, isNavigateToSameService)
      }
    },
  },
})
