























import { Tab } from '@docshouse/dh-ui-types'
import Vue, { PropType } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TabSearchMenu',
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },

  data() {
    return {
      menu: false,
    }
  },

  methods: {
    navigateToTab(tab: Tab) {
      this.$emit('navigateToTab', tab)
    },
  },
})
