

















































































import { ThemeDto } from '@docshouse/dh-facade-utils-ui'
import { LOCAL_STORAGE_KEYS, Pagination, RegistryClassDescriptor, TableHeader } from '@docshouse/dh-ui-types'
import Vue from 'vue'
import { mapActions } from 'vuex'
import systemSettings from '../../../public/system-settings.json'
import { convertPaginationToRequestParams } from '@docshouse/dh-ui-components'
import { mapMutations } from 'vuex'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme'
import axios from 'axios'

const defaultColorDesc = ['Темная тема', 'Светлая тема']
export default Vue.extend({
  name: 'SettingsTheme',

  data() {
    return {
      isSelectedTheme: false,
      isThemesListLoading: false,
      themes: [] as ThemeDto[],
      tableHeaders: [
        {
          text: 'themes.attributes.name',
          value: 'name',
          width: '34%',
          isVisible: true,
        },
        {
          text: 'themes.attributes.description',
          value: 'description',
          width: '33%',
          isVisible: true,
        },
        {
          text: 'themes.attributes.colorSchema',
          value: 'colors',
          width: '33%',
          isVisible: true,
        },
      ] as TableHeader<ThemeDto>[],
      pagination: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['creationDate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      } as Pagination<ThemeDto>,
      themeDescriptor: {} as RegistryClassDescriptor | undefined,
      selectedTheme: [] as ThemeDto[] | null,
    }
  },

  computed: {
    currentThemeTitle(): string | undefined {
      if (this.selectedTheme?.length) {
        return this.selectedTheme?.[0]?.description
      } else {
        const settingsTheme = this.$localStorageHandler('get', false, 'selectedTheme')
        if (settingsTheme && Object.keys(settingsTheme).length !== 0) {
          return settingsTheme.description
        } else {
          return 'Светлая тема'
        }
      }
    },
  },

  async created() {
    await this.getThemesData(this.pagination)

    const userSettingTheme = this.$localStorageHandler('get', false, 'selectedTheme')
    if (userSettingTheme) {
      this.selectedTheme?.push(userSettingTheme)
    } else {
      const light = this.themes.find((item) => item.name === 'light')
      this.selectedTheme?.push(light as ThemeDto)
    }
  },

  methods: {
    ...mapActions({
      getThemes: 'theme/getThemes',
      showMessage: 'events/showMessage',
    }),
    ...mapMutations({
      setSystemTheme: 'settings/setSystemTheme',
      setSystemCurrentTheme: 'settings/setSystemCurrentTheme',
      setUserSettingChangeStatus: 'settings/setUserSettingChangeStatus',
    }),

    async getThemesData(pagination?: Pagination<ThemeDto>) {
      this.isThemesListLoading = true
      const params = convertPaginationToRequestParams(pagination ?? this.pagination, 'OBJECT')
      try {
        const data = await this.getThemes(params)
        const themeData = this.regexColors(data)

        const systemThemes = JSON.parse(JSON.stringify(systemSettings.ui.themes))

        const defaultThemes = Object.entries(systemThemes).map(([name, colors], index) => ({
          name,
          colors,
          description: name === 'dark' ? defaultColorDesc[0] : name === 'light' ? defaultColorDesc[1] : '',
          id: `${name}-${index}`,
        }))
        this.themes = themeData.concat(defaultThemes as ThemeDto[])
      } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error) && error.message) {
          this.showMessage({ payload: { message: error.message, color: 'error' } })
        }
      } finally {
        this.isThemesListLoading = false
      }
    },

    regexColors(data: ThemeDto[]) {
      const res = data.map((item) => ({
        ...item,
        colors: JSON.parse(item.colors),
      }))
      return res
    },

    chooseTheme(themeKey: string) {
      const theme = this.themes.find((item) => item.name === themeKey)

      this.$vuetify.theme.dark = false

      this.$nextTick(() => {
        if (theme) {
          const isDark = themeKey === 'dark' ? true : false
          if (isDark) {
            this.$vuetify.theme.dark = true
            this.$vuetify.theme.themes.dark = {} as VuetifyThemeVariant
            this.$vuetify.theme.themes.dark = theme.colors as unknown as VuetifyThemeVariant
          } else {
            this.$vuetify.theme.dark = false
            this.$vuetify.theme.themes.light = {} as VuetifyThemeVariant
            this.$vuetify.theme.themes.light = theme.colors as unknown as VuetifyThemeVariant
          }

          const bufferTheme = this.$localStorageHandler('get', false, LOCAL_STORAGE_KEYS.VUETIFY_CONFIG)

          bufferTheme.theme.dark = this.$vuetify.theme.dark
          bufferTheme.theme.themes = this.$vuetify.theme.themes

          this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.VUETIFY_CONFIG, null, bufferTheme)
          this.$localStorageHandler('set', false, LOCAL_STORAGE_KEYS.DARK_THEME, null, bufferTheme.theme.dark)
          this.$localStorageHandler('set', false, 'selectedTheme', null, theme)
          this.$localStorageHandler('set', false, 'themes', null, bufferTheme.theme.themes)

          const systemTheme = this.$vuetify.theme.dark === true ? 'dark' : 'light'
          this.setSystemTheme(systemTheme)
          this.setSystemCurrentTheme(theme)
          this.setUserSettingChangeStatus(true)
          this.$nextTick(() => {
            this.$forceUpdate()
          })
        }
      })
      this.isSelectedTheme = false
    },
  },
})
