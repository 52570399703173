







































import { Tab, UserSettingCreateRequestDto, UserSettingDto } from '@docshouse/dh-ui-types'
import axios from 'axios'
import Vue, { PropType } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'TabHomepageMenu',
  props: {
    tab: {
      type: Object as PropType<Tab>,
      required: true,
    },

    isRoot: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
    }
  },

  computed: {
    ...mapGetters({
      getUserInfo: 'usersInfo/getUserInfo',
      userProfile: 'settings/userProfile',
      systemTheme: 'settings/systemTheme',
      systemLanguage: 'settings/systemLanguage',
      systemHomepage: 'settings/systemHomepage',
    }),
  },

  methods: {
    ...mapActions({
      showMessage: 'events/showMessage',
      updateSettingById: 'settings/updateSettingById',
      createSetting: 'settings/createSetting',
      fetchUserAccountSettings: 'settings/fetchUserAccountSettings',
    }),
    ...mapMutations({
      setSystemHomepage: 'settings/setSystemHomepage',
    }),

    async saveTabAsHome(tab: Tab) {
      if (tab.path !== this.systemHomepage) {
        this.setSystemHomepage(tab.path)
        await this.saveHomepageSettings()
      }
    },

    checkTabIsHomepage(tab: Tab) {
      return tab.path === this.systemHomepage
    },

    async resetHomepage() {
      this.setSystemHomepage(null)
      await this.saveHomepageSettings()
    },

    async saveHomepageSettings() {
      const currentSessionSettings = await this.getCurrentUserSessionSettings()

      const payload: UserSettingCreateRequestDto = {
        containerType: 'USER',
        container: this.getUserInfo?.preferredUsername,
        key: 'user-settings',
        userName: this.getUserInfo?.preferredUsername,
        application: 'ui-base',
        isGeneral: false,
        description: this.$t('sessionUserSettings') as string,
        value: JSON.stringify({
          language: this.systemLanguage,
          profile: this.userProfile,
          theme: this.systemTheme,
          homepage: this.systemHomepage,
        }),
      }

      try {
        if (currentSessionSettings) {
          await this.updateSettingById({
            id: currentSessionSettings.id,
            data: payload,
          })
        } else {
          await this.createSetting(payload)
        }

        this.showMessage({
          payload: {
            message: this.systemHomepage
              ? this.$t('notifications.homepageSetSuccess')
              : this.$t('notifications.homepageResetSuccess'),
            color: 'success',
          },
        })
      } catch (error) {
        if (axios.isAxiosError(error) && error.message) {
          this.showMessage({ payload: { message: error.message, color: 'error' } })
        }
      }
    },

    async getCurrentUserSessionSettings(): Promise<UserSettingDto | null | undefined> {
      const currentSessionSettings = await this.fetchUserAccountSettings()

      let settingByContainer = null

      if (currentSessionSettings && currentSessionSettings.length) {
        settingByContainer = currentSessionSettings.find(
          (setting: UserSettingDto) => setting.container === this.getUserInfo?.preferredUsername
        )
      }
      return settingByContainer
    },

    closeTabHandler() {
      this.$emit('close-tab')
    },
  },
})
