import { render, staticRenderFns } from "./SettingsUserResetDialog.vue?vue&type=template&id=6f67e9ae&scoped=true&"
import script from "./SettingsUserResetDialog.vue?vue&type=script&lang=ts&"
export * from "./SettingsUserResetDialog.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsUserResetDialog.vue?vue&type=style&index=0&id=6f67e9ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f67e9ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardActions,VListItemTitle,VSpacer})
