
























































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SettingsTheme from '@/components/settings/SettingsTheme.vue'
import SettingsRestoreDefaults from '@/components/settings/SettingsRestoreDefaults.vue'
import SettingsLocale from '@/components/settings/SettingsLocale.vue'
import SettingsSystemInfo from '@/components/settings/SettingsSystemInfo.vue'
import SettingsLogger from '@/components/settings/SettingsLogger.vue'
import SettingsRole from '@/components/settings/SettingsRole.vue'
import SettingsProfile from '@/components/settings/SettingsProfile.vue'
import SettingsUserResetDialog from '@/components/settings/SettingsUserResetDialog.vue'
import { UserSettingCreateRequestDto, UserSettingDto } from '@docshouse/dh-ui-types'
import axios from 'axios'

export default Vue.extend({
  name: 'SettingsAccount',
  components: {
    SettingsRestoreDefaults,
    SettingsTheme,
    SettingsLocale,
    SettingsSystemInfo,
    SettingsLogger,
    SettingsRole,
    SettingsProfile,
    SettingsUserResetDialog,
  },

  data() {
    return {
      isMenuOpen: false,
      isPinDrawer: false,
      isSettingsResetDialogOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      userRoles: 'auth/userRoles',
      isDrawerMini: 'ui/isDrawerMini',
      isDarkThemeAvailable: 'ui/isDarkThemeAvailable',
      isInternationalizationAvailable: 'ui/isInternationalizationAvailable',
      isSystemInfoAvailable: 'ui/isSystemInfoAvailable',
      isLoggerAvailable: 'ui/isLoggerAvailable',
      isRestoreDefaultsAvailable: 'ui/isRestoreDefaultsAvailable',
      isExpandDrawerOnHover: 'ui/isExpandDrawerOnHover',
      getUserInfo: 'usersInfo/getUserInfo',
      userProfile: 'settings/userProfile',
      systemTheme: 'settings/systemTheme',
      systemCurrentTheme: 'settings/systemCurrentTheme',
      systemLanguage: 'settings/systemLanguage',
      systemHomepage: 'settings/systemHomepage',
      isUserSettingChange: 'settings/isUserSettingChange',
      areAnySettingsToReset: 'settings/areAnySettingsToReset',
    }),
    userAvatarContent(): string {
      return this.getUserInfo?.activeProfile?.preferredUsername
        ? this.getUserInfo?.activeProfile.preferredUsername.slice(0, 1).toUpperCase()
        : this.getUserInfo?.preferredUsername.slice(0, 1).toUpperCase()
    },
    getTitle(): string {
      return this.getUserInfo?.preferredUsername
    },
    getUserName(): string {
      const family = this.getUserInfo?.familyName ?? ''
      const given = this.getUserInfo?.givenName ?? ''
      const middle = this.getUserInfo?.middleName ?? ''
      return family + ' ' + given + ' ' + middle
    },
  },

  watch: {
    isMenuOpen: {
      handler: async function (newVal) {
        if (newVal) {
          await this.checkUserSettingsForResetAvaiable()
        }
      },
    },
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      showMessage: 'events/showMessage',
      fetchUserAccountSettings: 'settings/fetchUserAccountSettings',
      createSetting: 'settings/createSetting',
      updateSettingById: 'settings/updateSettingById',
      deleteSettingById: 'settings/deleteSettingById',
      checkUserSettingsForResetAvaiable: 'settings/checkUserSettingsForResetAvaiable',
    }),
    ...mapMutations({
      setUserSettingChangeStatus: 'settings/setUserSettingChangeStatus',
    }),

    logoutHandler() {
      // @ts-ignore
      this.logout()
      setTimeout(() => {
        this.$router.push({ path: '/logout' })
      }, 500)
      /*if (process.env.NODE_ENV === 'development') {
        window.location.href = process.env.BASE_URL + process.env.VUE_APP_DEV_LOGOUT
      } else {
        window.location.href = process.env.BASE_URL + process.env.VUE_APP_PROD_LOGOUT
      }
      */
    },

    async getCurrentUserSessionSettings(): Promise<UserSettingDto | null | undefined> {
      const currentSessionSettings = await this.fetchUserAccountSettings()

      let settingByContainer = null

      if (currentSessionSettings && currentSessionSettings.length) {
        settingByContainer = currentSessionSettings.find(
          (setting: UserSettingDto) => setting.container === this.getTitle
        )
      }
      return settingByContainer
    },

    async saveAccountSettings() {
      const payload: UserSettingCreateRequestDto = {
        containerType: 'USER',
        container: this.getTitle,
        key: 'user-settings',
        userName: this.getTitle,
        application: 'ui-base',
        isGeneral: false,
        description: this.$t('sessionUserSettings') as string,
        value: JSON.stringify({
          language: this.systemLanguage,
          profile: this.userProfile,
          theme: this.systemTheme,
          homepage: this.systemHomepage,
          currentTheme: this.systemCurrentTheme,
        }),
      }
      const currentSessionSettings = await this.getCurrentUserSessionSettings()

      try {
        if (currentSessionSettings) {
          await this.updateSettingById({
            id: currentSessionSettings.id,
            data: payload,
          })
        } else {
          await this.createSetting(payload)
        }

        this.showMessage({
          payload: {
            message: this.$t('notifications.saveUserSessionSettingsSuccess'),
            color: 'success',
          },
        })
      } catch (error) {
        if (axios.isAxiosError(error) && error.message) {
          this.showMessage({ payload: { message: error.message, color: 'error' } })
        }
      } finally {
        this.setUserSettingChangeStatus(false)
      }
    },

    resetUserSettings() {
      this.isSettingsResetDialogOpen = true
    },
  },
})
