















import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import NavigationSidebar from '@/components/common/NavigationSidebar.vue'
import AppBar from '@/components/common/AppBar.vue'
import AppOverlay from '@/components/common/AppOverlay.vue'
import WorkspaceArea from '@/components/common/WorkspaceArea.vue'
import AppSnackbars from '@/components/common/AppSnackbars.vue'

export default Vue.extend({
  name: 'App',
  components: {
    AppBar,
    NavigationSidebar,
    WorkspaceArea,
    AppSnackbars,
    AppOverlay,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isInitialSystemSettingsReceived: 'settings/isInitialSystemSettingsReceived',
      isFailedToReceiveInitialSystemSettings: 'settings/isFailedToReceiveInitialSystemSettings',
      isEmbeddedInIframe: 'settings/isEmbeddedInIframe',
      iframeOptions: 'settings/iframeOptions',
      isUserPermissionsReceived: 'auth/isUserPermissionsReceived',
      r7Options: 'settings/r7Options',
      systemLanguage: 'settings/systemLanguage',
      systemTheme: 'settings/systemTheme',
    }),
    isAppBarVisible(): boolean {
      return this.isAuthenticated
    },
    isNavigationSidebarVisible(): boolean {
      return this.isAuthenticated && !this.isEmbeddedInIframe && !this.isR7Mode
    },
    isOverlayVisible(): boolean {
      return (
        (!this.isInitialSystemSettingsReceived && !this.isFailedToReceiveInitialSystemSettings) ||
        (this.isAuthenticated && !this.isUserPermissionsReceived)
      )
    },
    isR7Mode(): boolean {
      return !!this.r7Options
    },
    isLoginError(): boolean {
      return window.location.href.includes('/login?error')
    },
  },

  mounted() {
    this.subscribeToGlobalEvents()
  },

  beforeDestroy() {
    this.unsubscribeFromGlobalEvents()
  },

  methods: {
    ...mapActions({
      handleGlobalEvent: 'events/handleGlobalEvent',
    }),

    subscribeToGlobalEvents(): void {
      document.querySelector(`.${this.$baseAppName}`)?.addEventListener(this.$baseAppName, this.handleGlobalEvent)
    },

    unsubscribeFromGlobalEvents(): void {
      document.querySelector(`.${this.$baseAppName}`)?.removeEventListener(this.$baseAppName, this.handleGlobalEvent)
    },
  },
})
