var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"app-bar",attrs:{"app":"","dense":"","flat":""}},[_c('div',{staticClass:"app-bar__buttons-left"},[(_vm.isScrollTabButtonsVisible)?_c('dh-button',{staticClass:"app-bar__scroll-left-button",attrs:{"button-type":"icon","tile":"","ripple":false,"disabled":_vm.isScrollTabLeftButtonDisabled},on:{"click":function($event){return _vm.scrollTabsButtonHandler('left')}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('div',{staticClass:"app-bar__tabs",class:{
      'app-bar__tabs--scroll-buttons-visible': _vm.isScrollTabButtonsVisible,
      'app-bar__tabs--scroll-buttons-hidden': !_vm.isScrollTabButtonsVisible,
      'app-bar__tabs--close-all-tabs-button-visible': _vm.isCloseAllTabsButtonVisible,
      'app-bar__tabs--close-all-tabs-button-hidden': !_vm.isCloseAllTabsButtonVisible,
      'app-bar__tabs--navigation-drawer-mini': _vm.isDrawerMini,
      'app-bar__tabs--navigation-drawer-full': !_vm.isDrawerMini,
    }},[_c('div',{key:_vm.rootTab.id,staticClass:"app-bar__tab-item app-bar__tab-item--root d-flex justify-center align-center",class:{ 'app-bar__tab-item--active': _vm.rootTab.isActive },on:{"click":function($event){return _vm.navigateToTab(_vm.rootTab)}}},[_c('div',{staticClass:"app-bar__tab-item__title d-flex justify-center align-center text-uppercase"},[_c('span',{staticClass:"app-bar__tab-item__title__text dh-text-subtitle2"},[_vm._v(_vm._s(_vm.rootTab.title))]),(_vm.rootTab.title)?_c('TabHomepageMenu',{attrs:{"tab":_vm.rootTab,"is-root":""}}):_vm._e()],1)]),_c('div',{directives:[{name:"scroll",rawName:"v-scroll.self",value:(_vm.throttle(_vm.onScrollHandler, 300)),expression:"throttle(onScrollHandler, 300)",modifiers:{"self":true}},{name:"resize",rawName:"v-resize",value:(_vm.throttle(_vm.onResizeHandler, 300)),expression:"throttle(onResizeHandler, 300)"}],ref:"tabsScrollContainerRef",staticClass:"app-bar__tabs__scroll-container"},_vm._l((_vm.tabsExcludingRoot),function(tab){return _c('div',{key:tab.id,ref:"tabsRef",refInFor:true,staticClass:"app-bar__tab-item d-flex justify-center align-center",class:{ 'app-bar__tab-item--active': tab.isActive, 'app-bar__tab-item--root': tab.id === 'root' },on:{"click":function($event){return _vm.navigateToTab(tab)}}},[(tab.title)?_c('div',{staticClass:"app-bar__tab-item__title d-flex justify-center align-center text-uppercase"},[_c('v-tooltip',{attrs:{"bottom":"","z-index":"1010","max-width":"600","open-delay":"800"},on:{"click":function($event){return _vm.navigateToTab(tab)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('span',_vm._g(_vm._b({staticClass:"app-bar__tab-item__title__text dh-text-subtitle2"},'span',attrs,false),on),[_vm._v(_vm._s(tab.title))])]}}],null,true)},[_vm._v(" "+_vm._s(tab.title)+" ")]),(tab.isDirty)?_c('span',{staticClass:"app-bar__tab-item__title__dirty-state ml-1"},[_vm._v(" * ")]):_vm._e(),_c('TabHomepageMenu',{attrs:{"tab":tab},on:{"close-tab":function($event){return _vm.closeTabHandler(tab)}}})],1):_c('dh-spinner',{attrs:{"color":"tertiary"}}),(tab.id !== 'root')?_c('dh-button',{staticClass:"app-bar__tab-item__close-button",attrs:{"button-type":"icon","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.closeTabHandler(tab)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-close ")])],1):_vm._e()],1)}),0)]),_c('div',{staticClass:"app-bar__buttons-right"},[(_vm.isScrollTabButtonsVisible)?_c('dh-button',{staticClass:"app-bar__scroll-right-button",attrs:{"button-type":"icon","tile":"","ripple":false,"disabled":_vm.isScrollTabRightButtonDisabled},on:{"click":function($event){return _vm.scrollTabsButtonHandler('right')}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.isScrollTabButtonsVisible)?_c('TabSearchMenu',{attrs:{"tabs":_vm.tabsExcludingRoot},on:{"navigateToTab":_vm.navigateToTab}}):_vm._e(),_c('dh-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCloseAllTabsButtonVisible),expression:"isCloseAllTabsButtonVisible"}],staticClass:"app-bar__close-all-tabs-button",attrs:{"button-type":"icon","tooltip-content":_vm.$t('buttons.closeAllTabs'),"tooltip-attrs":{
        openDelay: 800,
      },"tile":"","ripple":false},on:{"click":_vm.closeAllTabsHandler}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-close-circle-outline")])],1),(_vm.isSearchAvailable)?_c('SearchFieldWrapper',{attrs:{"is-search-field-visible-model":_vm.isSearchFieldVisible},on:{"update:isSearchFieldVisibleModel":function($event){_vm.isSearchFieldVisible=$event},"update:is-search-field-visible-model":function($event){_vm.isSearchFieldVisible=$event}}}):_vm._e(),_c('dh-button',{staticClass:"app-bar__homepage-button",attrs:{"button-type":"icon","tooltip-content":_vm.$t('buttons.homepage'),"tooltip-attrs":{
        openDelay: 500,
      },"tile":"","ripple":false,"color":_vm.isCurrentPageHome ? 'success' : '',"disabled":_vm.isCurrentPageHome},on:{"click":_vm.goHomepage}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-home-circle")])],1),(_vm.isSettingsNotificationsVisible)?_c('SettingsNotifications'):_vm._e(),(_vm.isSettingsAccountVisible)?_c('SettingsAccount'):_vm._e()],1),_c('dh-confirm-dialog',{attrs:{"model":_vm.isConfirmCloseTabDialogOpen,"agree-button-content":_vm.$t('buttons.close'),"cancel-button-content":_vm.$t('buttons.cancel')},on:{"agree-clicked":_vm.confirmCloseTabHandler,"cancel-clicked":_vm.cancelCloseTabHandler},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dialogs.tabHasUnsavedChangesTitle'))+" ")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dialogs.tabHasUnsavedChangesContent'))+" ")]},proxy:true}])}),_c('dh-confirm-dialog',{attrs:{"model":_vm.isConfirmCloseAllTabsDialogOpen,"agree-button-content":_vm.$t('buttons.close'),"cancel-button-content":_vm.$t('buttons.cancel')},on:{"agree-clicked":_vm.confirmCloseAllTabsHandler,"cancel-clicked":_vm.cancelCloseAllTabsHandler},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dialogs.someTabsHasUnsavedChangesTitle'))+" ")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dialogs.someTabsHasUnsavedChangesContent'))+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }