




















import { LOCAL_STORAGE_KEYS, SERVICE_NAMES } from '@docshouse/dh-ui-types'
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'SettingsRestoreDefaults',

  data() {
    return {
      isConfirmDialogOpen: false,
    }
  },

  computed: {
    icon(): string {
      return 'mdi-broom'
    },
    tooltipContent(): TranslateResult {
      return this.$t('buttons.restoreDefaults')
    },
  },

  methods: {
    agreeConfirmDialogHandler() {
      this.restoreDefaultSettings()
      this.closeConfirmDialog()
    },

    restoreDefaultSettings() {
      const { SESSION_DATA, TABS, VUETIFY_CONFIG } = LOCAL_STORAGE_KEYS
      const ignoredLocalStorageKeys = [SESSION_DATA, `${SERVICE_NAMES.BASE}.${TABS}`, VUETIFY_CONFIG]

      Object.keys(localStorage).forEach((key) => {
        if (!ignoredLocalStorageKeys.includes(key)) {
          this.$localStorageHandler('remove', false, key)
        }
      })

      indexedDB.deleteDatabase('dh-documents-service')

      location.reload()
    },

    openConfirmDialog() {
      this.isConfirmDialogOpen = true
    },

    closeConfirmDialog() {
      this.isConfirmDialogOpen = false
    },
  },
})
