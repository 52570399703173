import api from '@/api/api'
import i18n from '@/plugins/i18n'
import jwt_decode from 'jwt-decode'
import { AxiosError } from 'axios'
import { localStorageHandler } from '@docshouse/dh-ui-components'
import { CHECK_TOKEN_EXPIRY_INTERVAL } from '@/common/constants'
import { getServiceRootComponent, isRefreshTokenRequired, navigate, showConsoleError } from '@/common/utils'
import { ActionContext, Commit, Dispatch } from 'vuex'
import {
  CustomSessionData,
  LoginActionPayload,
  PermitsUiDto,
  SessionData,
  UserDataDecodedDto,
} from '@/types/auth.types'
import {
  CustomEventDetail,
  LOCAL_STORAGE_KEYS,
  RequestPageParams,
  ServicePermissionDescription,
} from '@docshouse/dh-ui-types'
import { ServiceDescription } from '@/types/services.types'
import store from '..'

// todo: хранение токена в localStorage небезопасно
//  вариант 1: хранить access token в памяти приложения, а refresh token в в куки HttpOnly
//  (защищено от CSRF и менее подвержено XSS) https://medium.com/nuances-of-programming/полное-руководство-по-управлению-jwt-во-фронтенд-клиентах-graphql-b9b5103062a3
//  вариант 2: оба токена хранить в local storage, но с шифрованием https://medium.com/swlh/how-to-implement-refresh-token-functionality-front-end-eff58ce52564

interface AuthState {
  sessionData: SessionData | CustomSessionData | null
  userData: UserDataDecodedDto | null
  userPermissions: PermitsUiDto[] | null
  checkTokenExpiryIntervalId: number | null
}

const getDefaultState = () => {
  return {
    sessionData: null,
    userData: null,
    userPermissions: null,
    checkTokenExpiryIntervalId: null,
  } as AuthState
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    sessionData(state: AuthState) {
      return state.sessionData
    },
    isAuthenticated(): boolean {
      return document.cookie !== undefined
    },
    isCustomAuthorization(state: AuthState, getters: any, rootState: any): boolean {
      return rootState['settings'].initialSystemSettings?.ui?.isCustomAuthorization
    },
    userPermissions(state: AuthState): PermitsUiDto[] | null {
      return state.userPermissions
    },
    userPermissionsMap(state: AuthState): PermitsUiDto[] | null {
      const map = {} as any
      state.userPermissions?.forEach((item) => {
        map[item.serviceName] = { ...item }
      })
      return map
    },
    isUserPermissionsReceived(state: AuthState, getters: any): boolean {
      return getters.isCustomAuthorization
        ? getters.isAuthenticated
        : getters.isAuthenticated && !!state.userPermissions
    },
    userName(state: AuthState) {
      return state.userData?.preferred_username ?? 'unknown'
    },
    userData(state: AuthState) {
      return state.userData
    },
    userRoles(state: AuthState) {
      return state.userData?.realm_access.roles ?? []
    },
  },

  mutations: {
    setSessionData(state: AuthState, payload: SessionData) {
      state.sessionData = payload
    },

    setUserData(state: AuthState, payload: UserDataDecodedDto) {
      state.userData = payload
    },

    setUserPermissions(state: AuthState, payload: PermitsUiDto[]) {
      state.userPermissions = payload
    },

    setCheckTokenExpiryIntervalId(state: AuthState, payload: AuthState['checkTokenExpiryIntervalId']) {
      state.checkTokenExpiryIntervalId = payload
    },

    resetState(state: AuthState) {
      Object.assign(state, getDefaultState())
    },
  },

  actions: {
    checkSessionData({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }): void {
      const sessionData: SessionData = localStorageHandler('get', false, LOCAL_STORAGE_KEYS.SESSION_DATA)
      if (sessionData) {
        commit('setSessionData', sessionData)
        // dispatch('getUserInfo')
      }
    },

    async checkUserPermissions({ dispatch, getters }: { dispatch: Dispatch; getters: any }): Promise<any> {
      try {
        // if (!getters.isAuthenticated) {
        //   return
        // }
        const userPermissions: PermitsUiDto[] = getters.userPermissions
        if (!userPermissions) {
          await dispatch('getUserPermissions')
        }
        dispatch('defineUserPermissions')
      } catch (error) {
        showConsoleError({ message: 'Failed to get user permissions', error })
        dispatch('logout')
        setTimeout(() => {
          navigate({ path: '/auth' })
        }, 500)
      }
    },

    // getUserInfo({ commit, getters }: { commit: Commit; getters: any }) {
    //   const token = getters.sessionData?.access_token

    //   if (token) {
    //     if (!getters.isCustomAuthorization) {
    //       try {
    //         const decodedUserData = jwt_decode(token)
    //         commit('setUserData', decodedUserData)
    //       } catch (error) {
    //         showConsoleError({ message: 'Failed to get user info', error })
    //       }
    //     } else {
    //       commit('setUserData', {
    //         preferred_username: getters.sessionData.username,
    //         realm_access: { roles: getters.sessionData.roles },
    //       })
    //     }
    //   }
    // },

    async login(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      payload: LoginActionPayload
    ): Promise<boolean | Error> {
      try {
        let sessionData: SessionData | CustomSessionData

        if (!payload.isCustomAuthorization) {
          const response = await api.auth.login(payload)
          sessionData = {
            ...response.data,
            receive_timestamp: Date.now(),
          }
        } else {
          sessionData = {
            access_token: 'custom-authorization',
            username: payload.username!,
            roles: payload.roles!,
            receive_timestamp: Date.now(),
          }
        }

        commit('setSessionData', sessionData)
        // localStorageHandler('set', false, LOCAL_STORAGE_KEYS.SESSION_DATA, null, sessionData)

        if (rootGetters['settings/isRegistryEnabled']) {
          localStorageHandler(
            'set',
            false,
            LOCAL_STORAGE_KEYS.REGISTRY_DATA,
            null,
            rootGetters['settings/registryAppsDescription']
          )
        }

        dispatch('getUserInfo')

        return true
      } catch (error) {
        showConsoleError({ message: 'Failed to login', error })
        return error as Error
      }
    },

    async customLogin(
      { dispatch }: { dispatch: Dispatch },
      payload: LoginActionPayload & { source: CustomEventDetail['source'] }
    ): Promise<void> {
      const loginPayload = {
        ...payload,
        isCustomAuthorization: true,
      }
      const customLoginResult = await dispatch('login', loginPayload)

      if (customLoginResult instanceof Error) {
        dispatch('showMessage', {
          payload: { message: customLoginResult.message, color: 'error' },
          source: payload.source,
        })
      } else {
        const permissionsResult = await dispatch('getUserPermissions')

        if (permissionsResult instanceof Error) {
          dispatch('showMessage', {
            payload: { message: i18n.t('notifications.getUserPermissionsError'), color: 'error' },
            source: payload.source,
          })
          dispatch('logout')
        } else {
          navigate({ path: '/' })
        }
      }
    },

    async getUserPermissions(
      { commit, getters }: { commit: Commit; getters: any },
      payload?: { roles?: string[]; params?: RequestPageParams<PermitsUiDto> }
    ): Promise<boolean | Error> {
      try {
        let userPermissions: PermitsUiDto[] | []

        if (!getters.isCustomAuthorization) {
          const params = payload?.params ? payload.params : { page: 0, size: 99999 }
          const userInfo = store.getters['usersInfo/getUserInfo']
          const roles = userInfo?.activeProfile?.roles ?? userInfo?.roles

          const response = await api.auth.getUserPermissions(payload?.roles ?? roles, params)
          userPermissions = response.data
        } else {
          userPermissions = []
        }

        commit('setUserPermissions', userPermissions)

        return true
      } catch (error) {
        showConsoleError({ message: 'Failed to get user permissions', error })
        return error as Error
      }
    },

    defineUserPermissions({
      dispatch,
      getters,
      rootGetters,
    }: {
      dispatch: Dispatch
      getters: any
      rootGetters: any
    }): void {
      const userPermissions: PermitsUiDto[] = getters.userPermissions
      const servicesArray: ServiceDescription[] = rootGetters['services/servicesArray']

      const convertedUserPermissionsGroupedByService: Record<string, ServicePermissionDescription[]> = {}

      // формируем разрешения полученные из ACCESS_RIGHTS_SERVICE
      userPermissions.forEach((permit) => {
        convertedUserPermissionsGroupedByService[permit.serviceName] = permit.resources.flatMap((resource) => {
          return resource.actions.map((action) => {
            return {
              name: `${resource.resourceName}-${action}`,
            }
          })
        })
      })

      // todo: добавить проверку всех вложенных компонентов (рекурсивно). если среди userPermissions встречается serviceName = component.id,
      //  то необходимо смержить permissions. таким образом учитывается кейс, когда какой то web-component-embedded является полноценным
      //  сервисом и имеет свою запись в registry и permissions, например users-sync-service)
      // todo: учесть кейс и для isLocalSettings и для !isLocalSettings
      servicesArray.forEach((service: ServiceDescription) => {
        const rootComponent = getServiceRootComponent(service)
        if (rootComponent?.meta?.isLocalSettings) {
          // при использовании локальных настроек разрешения будут браться из system-settings.json
          convertedUserPermissionsGroupedByService[service.name] = rootComponent?.meta?.permissionsLocal ?? []
        }

        dispatch(
          'services/setServicePermissions',
          {
            serviceName: service.name,
            permissions: convertedUserPermissionsGroupedByService[service.name] ?? [],
          },
          { root: true }
        )
      })
    },

    // самый простой подход к обновлению токена - перехватывать ошибку 401, выполнять обновление и повторять запрос.
    // но в данной архитектуре такой подход стал бы сложным и многословным, так как запросы данных выполняют инстансы axios
    // сервисов, а за все процессы связанные с токеном должен отвечать ui-base.
    // подход с высчитыванием оптимального интервала для обновления токена тоже не подошел, так как существует кейс, в котором
    // юзер выходит из сна с просроченным токеном и выполняет запрос в рамках сервиса (setInterval не учитывает время сна)
    async checkTokenExpiry({ state, dispatch }: { state: AuthState; dispatch: Dispatch }): Promise<void> {
      if (isRefreshTokenRequired(state.sessionData!.receive_timestamp, (state.sessionData as SessionData).expires_in)) {
        const result = await dispatch('refreshToken')

        if (result instanceof Error) {
          dispatch(
            'events/showMessage',
            { payload: { message: i18n.t('notifications.authError'), color: 'error' } },
            { root: true }
          )
          dispatch('logout')
        } else {
          dispatch('startCheckTokenExpiryInterval')
        }
      } else if (!state.checkTokenExpiryIntervalId) {
        dispatch('startCheckTokenExpiryInterval')
      }
    },

    startCheckTokenExpiryInterval({
      state,
      commit,
      dispatch,
      getters,
    }: {
      state: AuthState
      commit: Commit
      dispatch: Dispatch
      getters: any
    }): void {
      if (getters.isCustomAuthorization) {
        return
      }

      if (state.checkTokenExpiryIntervalId) {
        dispatch('stopCheckTokenExpiryInterval')
      }

      const intervalId = setInterval(() => {
        dispatch('checkTokenExpiry')
      }, CHECK_TOKEN_EXPIRY_INTERVAL)
      commit('setCheckTokenExpiryIntervalId', intervalId)
    },

    stopCheckTokenExpiryInterval({ state, commit }: { state: AuthState; commit: Commit; dispatch: Dispatch }): void {
      clearInterval(state.checkTokenExpiryIntervalId!)
      commit('setCheckTokenExpiryIntervalId', null)
    },

    async refreshToken({ state, commit }: { state: AuthState; commit: Commit }): Promise<boolean | Error> {
      try {
        const response = await api.auth.refreshToken((state.sessionData as SessionData).refresh_token)
        const sessionData: SessionData = { ...response.data, receive_timestamp: Date.now() }
        commit('setSessionData', sessionData)
        // localStorageHandler('set', false, LOCAL_STORAGE_KEYS.SESSION_DATA, null, sessionData)

        return true
      } catch (error) {
        showConsoleError({ message: 'Failed to refresh token', error })
        return error as Error
      }
    },

    logout({ state, commit, dispatch }: { state: AuthState; commit: Commit; dispatch: Dispatch }): void {
      dispatch('ui/closeAllTabs', null, { root: true })

      setTimeout(() => {
        if (state.checkTokenExpiryIntervalId) {
          dispatch('stopCheckTokenExpiryInterval')
        }

        commit('auth/resetState', null, { root: true })
        dispatch('clearLocalStorage', [LOCAL_STORAGE_KEYS.VUETIFY_CONFIG, 'selectedTheme', 'themes'])

        // navigate({ path: '/auth' })
      })
    },

    clearLocalStorage(ctx: ActionContext<AuthState, any>, ignoredKeys: string[] = []) {
      for (const key in localStorage) {
        if (!ignoredKeys.includes(key)) {
          localStorage.removeItem(key)
        }
      }
    },
  },
}
